import { DialogService }                   from 'aurelia-dialog';
import { bindable, inject }                from 'aurelia-framework';
import { BaseListViewModel }               from 'base-list-view-model';
import { CreateRevisionMaterialTypeModal } from 'modules/management/specifications/specifications/specification-revisions/specification-revision-material-types/create';
import { EditRevisionMaterialTypeModal }   from 'modules/management/specifications/specifications/specification-revisions/specification-revision-material-types/edit';
import { ViewRevisionMaterialTypeModal }   from 'modules/management/specifications/specifications/specification-revisions/specification-revision-material-types/view';
import { AppContainer }                    from 'resources/services/app-container';
import { RevisionMaterialTypesRepository } from './services/repository';

@inject(AppContainer, RevisionMaterialTypesRepository, DialogService)
export class ListRevisionMaterialTypes extends BaseListViewModel {

    listingId = 'management-specifications-specification-revision-material-types-listing';

    @bindable headerTitle    = 'listing.management.specifications.specification-revision-material-types';
    @bindable newRecordRoute = null;
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogService
     */
    constructor(appContainer, repository, dialogService) {
        super(appContainer);

        this.repository    = repository;
        this.dialogService = dialogService;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.specifications.specifications.revisions.material-types.manage',
            'management.specifications.specifications.revisions.material-types.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.specificationRevision = params.model;
        this.readonly              = params.readonly;

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      {
                search:          (criteria) => this.search(criteria),
                destroy:         (id) => this.repository.destroy(id),
                destroySelected: (ids) => this.repository.destroySelected(ids),
            },
            show:            {
                action:  (row) => this.openModal(row, ViewRevisionMaterialTypeModal),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.specifications.specifications.revisions.material-types.manage', 'management.specifications.specifications.revisions.material-types.view']),
            },
            edit:            {
                action:  (row) => this.openModal(row, EditRevisionMaterialTypeModal),
                visible: (row) => !this.readonly && this.appContainer.authenticatedUser.can(['management.specifications.specifications.revisions.material-types.manage', 'management.specifications.specifications.revisions.material-types.edit']),
            },
            destroy:         !this.readonly && this.appContainer.authenticatedUser.can(['management.specifications.specifications.revisions.material-types.manage', 'management.specifications.specifications.revisions.material-types.delete']),
            destroySelected: !this.readonly,
            buttons:         [
                {
                    label:            'form.button.create-new',
                    icon:             'icon-plus3',
                    visible:          !this.readonly,
                    action:           () => this.openModal(this, CreateRevisionMaterialTypeModal),
                    outside_dropdown: true,
                    color:            'success',
                },
            ],
            selectable:      !this.readonly,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'sector',
                    name:  'sector_translations.description',
                    title: 'form.field.sector',
                },
                {
                    data:  'created_by',
                    name:  'users.name',
                    title: 'form.field.created-by',
                },
                {
                    data:       'material_types',
                    name:       'material_types',
                    title:      'form.field.material-types',
                    type:       'processed',
                    processor:  (row) => `<ul>` + row.material_types.map((type) => `<li>${type}</li>`).join('') + `</ul>`,
                    searchable: false,
                    orderable:  false,
                },
                {
                    data:            'created_at',
                    name:            'specification_revisions.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.repository.search(this.specificationRevision.id, criteria);
    }

    /**
     * Opens the given modal
     */
    openModal(model, viewModel) {
        return this.dialogService
            .open({ viewModel: viewModel, model: model })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    this.datatable.instance.reload();
                }
            });
    }
}
