import { DialogService }                    from 'aurelia-dialog';
import { bindable, inject }                 from 'aurelia-framework';
import { BaseListViewModel }                from 'base-list-view-model';
import { CreateSpecificationRevisionModal } from 'modules/management/specifications/specifications/specification-revisions/create';
import { EditSpecificationRevisionModal }   from 'modules/management/specifications/specifications/specification-revisions/edit';
import { SpecificationRevisionsRepository } from 'modules/management/specifications/specifications/specification-revisions/services/repository';
import { ViewSpecificationRevisionModal }   from 'modules/management/specifications/specifications/specification-revisions/view';
import { AppContainer }                     from 'resources/services/app-container';

@inject(AppContainer, SpecificationRevisionsRepository, DialogService)
export class ListSpecificationRevisions extends BaseListViewModel {

    listingId = 'management-specifications-specifications-revisions-listing';

    @bindable headerTitle    = null;
    @bindable newRecordRoute = 'management.specifications.specifications.revisions.create';
    @bindable repository;
    @bindable datatable;
    @bindable specification;

    readonly = false;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogService
     */
    constructor(appContainer, repository, dialogService) {
        super(appContainer);

        this.repository    = repository;
        this.dialogService = dialogService;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.specifications.specifications.revisions.manage',
            'management.specifications.specifications.revisions.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.specification = params.model;
        this.readonly      = params.readonly;

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      {
                search:          (criteria) => this.search(criteria),
                destroy:         (id) => this.repository.destroy(id),
                destroySelected: (ids) => this.repository.destroySelected(ids),
            },
            show:            {
                action:  (row) => this.openModal(row, ViewSpecificationRevisionModal),
                visible: () => this.appContainer.authenticatedUser.can(['management.specifications.specifications.revisions.manage', 'management.specifications.specifications.revisions.view']),
            },
            edit:            {
                action:  (row) => this.openModal(row, EditSpecificationRevisionModal),
                visible: () => !this.readonly && this.appContainer.authenticatedUser.can(['management.specifications.specifications.revisions.manage', 'management.specifications.specifications.revisions.edit']),
            },
            destroy:         !this.readonly && this.appContainer.authenticatedUser.can(['management.specifications.specifications.revisions.manage', 'management.specifications.specifications.revisions.delete']),
            destroySelected: !this.readonly,
            actions:         [],
            options:         [],
            buttons:         [
                {
                    label:            'form.button.create-new',
                    icon:             'icon-plus3',
                    visible:          !this.readonly && this.appContainer.authenticatedUser.can(['management.specifications.specifications.revisions.manage', 'management.specifications.specifications.revisions.create']),
                    action:           () => this.openModal(this, CreateSpecificationRevisionModal),
                    outside_dropdown: true,
                    color:            'success',
                },
            ],
            selectable:      true,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'edition_number',
                    name:  'specification_revisions.edition_number',
                    title: 'form.field.edition-number',
                },
                {
                    data:  'revision_number',
                    name:  'specification_revisions.revision_number',
                    title: 'form.field.revision-number',
                },
                {
                    data:            'effective_date',
                    name:            'specification_revisions.effective_date',
                    title:           'form.field.effective-date',
                    valueConverters: [
                        {
                            name:   'dateTimeFormat',
                            format: 'YYYY-MM-DD',
                        },
                    ],
                },
                {
                    data:  'code',
                    name:  'specification_revisions.code',
                    title: 'form.field.code',
                },
                {
                    data:    'created_by',
                    name:    'users.name',
                    title:   'form.field.created-by',
                    display: false,
                },
                {
                    data:            'created_at',
                    name:            'specification_revisions.created_at',
                    title:           'form.field.created-at',
                    display:         false,
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.repository.search(this.specification.id, criteria);
    }

    /**
     * Opens the given modal
     */
    openModal(model, viewModel) {
        return this.dialogService
            .open({ viewModel: viewModel, model: model })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    this.datatable.instance.reload();
                }
            });
    }
}
