import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class SpecificationRevisionsRepository extends BaseRepository {
    baseUrl          = 'management/specifications/specifications/revisions';
    specificationUrl = 'management/specifications/specifications';

    /**
     * Performs a search given some criteria
     *
     * @param specificationId
     * @param criteria
     *
     * @returns {*}
     */
    search(specificationId, criteria) {
        return this.httpClient.post(`${this.specificationUrl}/${specificationId}/revisions/search`, criteria);
    }

    /**
     * Creates a record
     *
     * @param specificationId
     * @param data
     *
     * @returns {*}
     */
    create(specificationId, data) {
        return this.httpClient.post(`${this.specificationUrl}/${specificationId}/revisions`, { ...data, specification_id: specificationId });
    }
}
